import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { date } from "yup";
import { getPickupDate, locationByAddress } from "../../../api";

const stateLabelValues = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "American Samoa", value: "AS" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "District of Columbia", value: "DC" },
  { label: "States of Micronesia", value: "FM" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Guam", value: "GU" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Marshall Islands", value: "MH" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregan", value: "OR" },
  { label: "Palau", value: "PW" },
  { label: "Pennsilvania", value: "PA" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virgin Islands", value: "VI" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" }
];

export const getContactSubs = createAsyncThunk(
  "subscriptions",
  async (customerSubscriptions) => {
    let params = {
      locationId: "",
      pickupFrequency: "",
      routeDay: ""
    };

    let dayDisplay = "";
    let monthDisplay = "";
    let currentDay = "";
    let stateName = "";
    let subscriptionsArr = [];

    if (customerSubscriptions.length > 0) {

      for (let i = 0; i < customerSubscriptions.length; i++) {
        if (customerSubscriptions[i].data) {
          params = {
            locationId: customerSubscriptions[i].data.locationId || "",
            pickupFrequency:
              customerSubscriptions[i].data.pickupFrequency || "",
            routeDay: customerSubscriptions[i].data.routeDay || ""
          };
        } else {
          params = {
            locationId: customerSubscriptions[i].locationId || "",
            pickupFrequency: customerSubscriptions[i].pickupFrequency || "",
            routeDay: customerSubscriptions[i].routeDay || ""
          };
        }

        let pickupDate = {};

        if (params.routeDay) {
          pickupDate = await getPickupDate(params);
        }

        if (pickupDate?.data?.dates?.length > 0) {
          let pickupData = pickupDate.data.dates;

          currentDay = pickupData[0].split("/")[1];

          let newDate = new Date(pickupData);

          dayDisplay = newDate.toLocaleString("en-us", { weekday: "long" });
          monthDisplay = newDate.toLocaleString("en-us", { month: "long" });
        }

        if (customerSubscriptions[i].data) {
          stateName = customerSubscriptions[i].data.serviceAddress.state || '';
        } else {
          stateName = customerSubscriptions[i].stateName || '';
        }

        for (let i = 0; i < stateLabelValues.length; i++) {
          if (stateLabelValues[i].value === stateName) {
            stateName = stateLabelValues[i].label;
          }
        }

        let serviceByLocation = [];

        if (customerSubscriptions[i].data) {
          if (
            customerSubscriptions[i].data.serviceAddress?.latitude?.length > 0 &&
            customerSubscriptions[i].data.serviceAddress?.longitude?.length > 0 &&
            stateName.length > 0
          ) {
            serviceByLocation = await locationByAddress(
              customerSubscriptions[i].data.serviceAddress?.latitude,
              customerSubscriptions[i].data.serviceAddress?.longitude,
              stateName
            );
          }
        } else {
          if (
            customerSubscriptions[i]?.latitude?.length > 0 &&
            customerSubscriptions[i]?.longitude?.length > 0 &&
            stateName.length > 0
          ) {
            serviceByLocation = await locationByAddress(
              customerSubscriptions[i].latitude,
              customerSubscriptions[i].longitude,
              stateName
            );
          }
        }

        // route_day OR zone and pickup_frequency must be passed in to get a 200 response
        if (customerSubscriptions[i].data) {
          subscriptionsArr.push({
            serviceByLocation,
            stateName,
            dayDisplay,
            monthDisplay,
            currentDay,
            paymentFrequency: customerSubscriptions[i].data.paymentFrequency,
            subscriptionServicesEnrolled:
              customerSubscriptions[i].data.subscriptionServicesEnrolled,
            locationId: customerSubscriptions[i].data.locationId || "",
            pickupFrequency:
              customerSubscriptions[i].data.pickupFrequency || "",
            routeDay: customerSubscriptions[i].data.routeDay || "",
            billingAddress: customerSubscriptions[i].data.billingAddress,

            serviceAddress1: customerSubscriptions[i].data.serviceAddress.line1,
            serviceAddress2: customerSubscriptions[i].data.serviceAddress.line2,
            city: customerSubscriptions[i].data.serviceAddress.city,
            stateAbrv: customerSubscriptions[i].data.serviceAddress.state,
            postalCode: customerSubscriptions[i].data.serviceAddress.postalCode,
            serviceAddressDisplay: customerSubscriptions[i].data.serviceAddress
              .line2
              ? `${customerSubscriptions[i].data.serviceAddress.line1} ${customerSubscriptions[i].data.serviceAddress.line2}, ${customerSubscriptions[i].data.serviceAddress.city} ${customerSubscriptions[i].data.serviceAddress.state}, ${customerSubscriptions[i].data.serviceAddress.postalCode}`
              : `${customerSubscriptions[i].data.serviceAddress.line1}, ${customerSubscriptions[i].data.serviceAddress.city} ${customerSubscriptions[i].data.serviceAddress.state}, ${customerSubscriptions[i].data.serviceAddress.postalCode}`,
            subscriptions: customerSubscriptions[i].data.subscriptions,
            totalSubscriptionAmount:
              customerSubscriptions[i].data.totalSubscriptionAmount,
            balance: customerSubscriptions[i].data.balance,
            latitude:
              customerSubscriptions[i].data.serviceAddress.latitude || "",
            longitude:
              customerSubscriptions[i].data.serviceAddress.longitude || "",
            dealId: customerSubscriptions[i].dealId,
            contactId: customerSubscriptions[i].contactId,
            billId: customerSubscriptions[i].data.stripeCustomerId,
            isFranchised: customerSubscriptions[i].data.isFranchised,
            nextPaymentDate: customerSubscriptions[i].data.nextPaymentDate,
            locationZone: customerSubscriptions[i].data.locationZone,
            firstPickupDate: customerSubscriptions[i].data.firstPickupDate,
            status: customerSubscriptions[i].data.status,
            cancelationDate: customerSubscriptions[i].data.cancelationDate,
            customerType: customerSubscriptions[i].data.customerType,
            dealName: customerSubscriptions[i].data.dealName,
            monthFreeCancelationOfferRedeemed:
              customerSubscriptions[i].data.monthFreeCancelationOfferRedeemed,
            paymentMethod: customerSubscriptions[i].data.paymentMethod
          });
        } else {
          subscriptionsArr.push({
            serviceByLocation,
            stateName,
            dayDisplay,
            monthDisplay,
            currentDay,
            paymentFrequency: customerSubscriptions[i].paymentFrequency,
            subscriptionServicesEnrolled:
              customerSubscriptions[i].subscriptionServicesEnrolled,
            locationId: customerSubscriptions[i].locationId || "",
            pickupFrequency: customerSubscriptions[i].pickupFrequency || "",
            routeDay: customerSubscriptions[i].routeDay || "",
            billingAddress: customerSubscriptions[i].billingAddress,

            serviceAddress1: customerSubscriptions[i].serviceAddress1,
            serviceAddress2: customerSubscriptions[i].serviceAddress2,
            city: customerSubscriptions[i].city,
            stateAbrv: customerSubscriptions[i].stateAbrv,
            postalCode: customerSubscriptions[i].postalCode,

            serviceAddressDisplay:
              customerSubscriptions[i].serviceAddressDisplay,
            subscriptions: customerSubscriptions[i].subscriptions,
            totalSubscriptionAmount:
              customerSubscriptions[i].totalSubscriptionAmount,
            balance: customerSubscriptions[i].balance,
            latitude: customerSubscriptions[i].latitude || "",
            longitude: customerSubscriptions[i].longitude || "",
            dealId: customerSubscriptions[i].dealId,
            contactId: customerSubscriptions[i].contactId,
            billId: customerSubscriptions[i].billId,
            isFranchised: customerSubscriptions[i].isFranchised,
            nextPaymentDate: customerSubscriptions[i].nextPaymentDate,
            locationZone: customerSubscriptions[i].locationZone,
            firstPickupDate: customerSubscriptions[i].firstPickupDate,
            status: customerSubscriptions[i].status,
            cancelationDate: customerSubscriptions[i].cancelationDate,
            customerType: customerSubscriptions[i].customerType,
            dealName: customerSubscriptions[i].dealName,
            monthFreeCancelationOfferRedeemed:
              customerSubscriptions[i].monthFreeCancelationOfferRedeemed,
            paymentMethod: customerSubscriptions[i].paymentMethod
          });
        }
      }
    }
    return subscriptionsArr;
  }
);

const dealSlice = createSlice({
  name: "subscriptions",
  initialState: {
    subscription: [],
    status: null
  },
  extraReducers: {
    [getContactSubs.pending]: (state, action) => {
      state.status = "loading";
    },
    [getContactSubs.fulfilled]: (state, { payload }) => {
      state.subscription = payload;
      state.status = "success";
    },
    [getContactSubs.rejected]: (state, action) => {
      state.status = "failed";
    }
  }
});

export default dealSlice.reducer;
