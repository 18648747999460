// eslint-disable-next-line  no-unused-vars
import React, { useState, useEffect } from "react";
import {
  HomeServiceSvg,
  // HomeServiceSvg,
  UserSvg
  // PlusSvg,
  // EditSvg
} from "../../components/Svgs";
import { MdDelete } from "react-icons/md";
import Header from "../../components/Header";
import { useNavigate } from "react-router";
import AppInput from "../../components/AppInput";
import { Modal } from "react-responsive-modal";
import AppButton from "../../components/AppButton";
import useWindowDimensions from "../../Hook/useWindowDimensions";
import { OptimizelyFeature } from "@optimizely/react-sdk";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { hideMenuBar } from "../../store/features/Menu/HideMenu";
import { updateServicePaymentFreq } from "../../store/features/ChangeServicePlan/UpdatePaymentFrequency";
import {
  capitalizeFirstLetterofEachWord,
  capitalizeFirstLetterofFirstWord
} from "../helper/index";
import { selectedDeal } from "../../store/features/deal/selectedDeal";
import { sentryCaptureError } from "../../components/utils/index";
import { getContactSubs } from "../../store/features/deal/getContactSubs";
import { createBillingPortalSession } from "../../api"

function Services() {
  const navigate = useNavigate();
  // @ts-ignore
  const dispatch = useDispatch<AppDispatch>();
  const { width } = useWindowDimensions();
  const hasMobile = width <= 768;
  const discontinuedLocationsEligibleToReactivate = ['ut-sanpete-county'];

  // const [show, setShow] = useState(true);
  const [hasPaymentSection, sethasPaymentSection] = useState(false);
  // @ts-ignore
  const loggedInUser = useSelector((state) => state.customerData.data);
  // @ts-ignore
  const userSub = useSelector((state) => state.getContactSubs.subscription);
  // @ts-ignore
  let changePlan = useSelector((state) => state.hideMenuBar);
  // @ts-ignore
//   const selectedDealObj = useSelector((state) => state.selectedDeal.deal);

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [activeSection, setActiveSection] = React.useState("billing");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [serviceRemoved, setServiceRemoved] = useState(false);
  const [service, setService] = useState({});
  const [serviceRemovedError, setServiceRemovedError] = useState(false);
  const [address, setAddress] = useState([]);
  const [subscription, setSubscription] = useState([]);
  const [currentIndexOfDealObj, setCurrentIndexOfDealObj] = useState(0);

  const [state, setState] = React.useState<any>({
    activeAddress: {},
    isOpen: false,
    isLoading: false,
    paymentCards: [],
    defaultID: "",
    hasApiError: false,
    enableSubscriptionReactivationModal: false,
    hasReenabledSubscription: false,
    billingPortalUrls: []
  });

  useEffect(() => {
    getUserSubscriptionsHandler();
    clearNewPaymentFreqHandler({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser.user, dispatch, changePlan]);

  const getUserSubscriptionsHandler = async () => {
    setState({ ...state, isLoading: true });

    try {
      if (userSub?.length > 0) {
        let subscription = [];

        subscription = userSub[currentIndexOfDealObj]?.subscriptions[0]?.items || [];

        setSubscription(subscription);
        setAddress(userSub);
        
        setState({
            ...state,
            isLoading: false,
            activeAddress: userSub[currentIndexOfDealObj],
        });

        let billingPortalUrls = [];
        for (const sub of userSub) {
            const url = await createBillingPortalUrl(sub.billId, sub.locationId, sub.locationZone, sub.isFranchised);
            billingPortalUrls.push(url);
        }

        setState({
            ...state,
            isLoading: false,
            activeAddress: userSub[currentIndexOfDealObj],
            billingPortalUrls: billingPortalUrls
        });

        await updateReduxSelectedDeal(userSub[currentIndexOfDealObj]);
      } else {
        setState({ ...state, isLoading: false });
      }
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
        hasApiError: error?.message || "Something went wrong"
      });
      console.error(error);
    }
  };

  useEffect(() => {
    if (userSub) {
      getUserSubscriptionsHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSub]);

  const selectedDealData = async (obj) => {
    setState({
      ...state,
      activeAddress: obj.activeAddress
    });

    setSubscription(obj.activeAddress.subscriptions);

    await updateReduxSelectedDeal(obj.activeAddress);
  };

  const createBillingPortalUrl = async (stripeCustomerId: string, locationId: string, locationZone: string, isFranchised: boolean) => {
    if(stripeCustomerId && locationId) {
        const res = await createBillingPortalSession({stripeCustomerId, locationId, locationZone, isFranchised});
        return res.billingPortalUrl;
    }
  }

  const updateReduxSelectedDeal = async (obj) => {
    try {
      await dispatch(selectedDeal(obj));
    } catch (err) {
      sentryCaptureError(err, { extra: { obj } });
      console.log("error with selecting deal", err);
    }
  };

  /**
   * set state based off hubspot customer info API call
   */
  // const getCustomerIds = async () => {
  //   const user = loggedInUser.user;
  //   return user?.attributes?.["custom:hubspotContactId"];
  // };

  const onBackPressHandler = () => {
    if (hasPaymentSection) {
      sethasPaymentSection(false);
    } else {
      navigate("/dashboard");
    }
  };

  const openModalHandler = (serviceItem) => {
    setService(serviceItem);
  };

  // created to hide menu bar once clicked for full screen display to change plan
  const hideMenuHandler = async () => {
    try {
      // @ts-ignore
      await dispatch(hideMenuBar(true));
    } catch (err) {
      return `Error when updating state via redux, ${err}`;
    }
  };

  const clearNewPaymentFreqHandler = async (clearNewPaymentFreq) => {
    try {
      // @ts-ignore
      await dispatch(updateServicePaymentFreq(clearNewPaymentFreq));
    } catch (err) {
      return `Error when updating state via redux, ${err}`;
    }
  };

  const nextPaymentDateHandler = (date) => {
    let result = "No date";

    if (date) {
      let newDate = new Date(date);
      let monthDisplay = newDate.toLocaleString("en-us", { month: "long" });
      let dayDisplay = newDate.toLocaleString("en-us", { day: "numeric" });
      let dayYear = newDate.toLocaleString("en-us", { year: "numeric" });

      result = `${monthDisplay} ${dayDisplay}, ${dayYear}`;
    }
    return result;
  };

  const paymentFrequencyHandler = (frequency) => {
    let paymentFrequency = "";
    if (frequency === "monthly") {
      paymentFrequency = "month";
    } else if (frequency === "quarterly") {
      paymentFrequency = "quarter";
    } else if (frequency === "semiannually") {
      paymentFrequency = "semi-annually";
    } else if (frequency === "annually") {
      paymentFrequency = "year";
    } else {
      paymentFrequency = frequency;
    }
    return paymentFrequency;
  };

  const billingPlanHandler = (frequency) => {
    if (frequency === "monthly") {
      return "monthly";
    } else if (frequency === "quarterly") {
      return "quarterly";
    } else if (frequency === "semiannually") {
      return "semiannual";
    } else if (frequency === "annually") {
      return "annual";
    } else if (frequency === 'monthly_arrears') {
        return 'monthly arrears';
    }
  };
  // const Credits = () => {
  //   let credit = "loading";
  //   if (userSub) {
  //     credit = `${userSub?.balance} (Issued on 09/20/2022)`;
  //   }

  //   return credit;
  // };

  const updateReduxContactSub = async (arr) => {
    try {
      // @ts-ignore
      await dispatch(getContactSubs(arr));
    } catch (err) {
      sentryCaptureError(err, { extra: { arr } });
      console.log("error with updating contact subs via Services", err);
    }
  };

  const formatStatusDisplayText = (status) => {
    const containsUnderScore = status.split("").includes("_");
    let result = '';

    if(status === 'no_payment_information') {
        result = 'No payment method provided - click the link below to add a payment method and start service'
    } else if (containsUnderScore) {
      let str = status.replaceAll("_", " ");
      result = str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      result = status.charAt(0).toUpperCase() + status.slice(1);
    }

    return result;
  }

  const ref = React.useRef<HTMLDivElement>(null);

  let serviceTypeArr = state.activeAddress.subscriptionServicesEnrolled;
  let displayArr = [];
  let displayServiceType = "";

  if (serviceTypeArr) {
    for (let i = 0; i < serviceTypeArr.length; i++) {
      displayArr.push(`${capitalizeFirstLetterofEachWord(serviceTypeArr[i])}`);
    }
    displayServiceType = displayArr.join(", ");
  }

  return (
    <>
      <div className="app-main-content main-seaction">
        <section
          className={`${!hasMobile ? "card-section-web section-bg-card active" : "active"
            } `}
        >
          <div className={hasMobile ? "mobile-account-background" : ""}>
            <div className={`page-account`}>
              {hasMobile ? (
                <Header
                  hasBack
                  onBackPress={onBackPressHandler}
                  isSettings={false}
                  title={`${hasPaymentSection ? "Payment methods" : "My plan"
                    }`}
                  icon={""}
                />
              ) : (
                <h3 className="w500" style={{ borderBottom: 0 }}>
                  My plan
                </h3>
              )}
              {state.isLoading ? (
                <ThreeDots color="#3EB5AB" height={30} width={30} />
              ) : (
                <></>
              )}

              {userSub && (
                <>
                  <Modal
                    ref={ref}
                    styles={{ modal: { borderRadius: 20 } }}
                    center
                    open={state.isOpen}
                    onClose={() => setState({ ...state, isOpen: false })}
                    onAnimationEnd={() => {
                      if (!state.isOpen) {
                        if (ref.current) {
                          ref.current.style.opacity = "0%";
                        }
                        if (ref.current?.parentElement?.parentElement) {
                          ref.current.parentElement.parentElement.style.opacity =
                            "0%";
                        }
                      }
                    }}
                  >
                    <div className={"billing-modal-container"}>
                      <h2>Nickname for this address</h2>
                      <AppInput
                        label={""}
                        onChange={() => { }}
                        placeholder={""}
                        value={userSub?.name ? userSub?.name : ""}
                      />
                      <AppButton
                        onClick={() => setState({ ...state, isOpen: false })}
                        title={"Ok"}
                        className={"active"}
                      />
                    </div>
                  </Modal>
                  
                   { /* Modal for customers to reactivate their account */ }
                  <Modal
                    ref={ref}
                    styles={{ modal: { borderRadius: 20 } }}
                    center
                    open={state.enableSubscriptionReactivationModal}
                    onClose={() => setState({ ...state, isOpen: false })}
                  >
                    <div style={{ marginLeft: 15, marginTop: 5, padding: 20}}>
                        <div
                            className="form-grop"
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <input
                                type="checkbox"
                                checked={state.hasReenabledSubscription===true}
                                data-toggle="collapse"
                                data-target="#collapseExample"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                                style={{height: '1em', width: '1em'}}
                                onChange={() => {
                                    setState({...state, hasReenabledSubscription: !state.hasReenabledSubscription})
                                }}
                            />
                            <p style={{paddingLeft: 10}}>I would like to reactivate my Recyclops subscription</p>
                        </div>
                            <span style={{fontStyle: 'italic'}}>Your will receive an email when your service is set to start again. If you would like to modify your subscription, please submit a ticket at <a href="https://ask.recyclops.com/kb-tickets/new" target="_blank" rel="noreferrer" style={{fontStyle: 'italic', fontSize: 'inherit'}}>ask.recyclops.com</a>, or call us at 801-709-1509.</span>
                        
                    </div>
                  </Modal>
                  <div
                    className={`${hasMobile ? "mobile-layout-container" : ""} `}
                  >
                    {!hasPaymentSection ? (
                      <>
                        {/* Address Sections */}
                        <div className={"address-cards"}>
                          {address.map((item, index) => (
                            <div
                              key={item.dealId}
                              className={`address-card-container ${item.dealId === state.activeAddress.dealId
                                ? "active"
                                : ""
                                }`}
                            >
                              <div
                                className={`address-card ${item.dealId === state.activeAddress.dealId
                                  ? "active"
                                  : ""
                                  }`}
                                // onClick={() =>
                                //   setState({ ...state, activeAddress: item })
                                // }
                                onClick={() =>
                                  {
                                    selectedDealData({ activeAddress: item })
                                    setCurrentIndexOfDealObj(index)
                                  }
                                }
                              >
                                <div>
                                  <HomeServiceSvg />
                                </div>
                                <label>{`Address ${index + 1}`}</label>
                              </div>

                              {/* <div
                                className={`address-card add-address`}
                                onClick={() => { }}
                              >
                                <div
                                  onClick={() =>
                                    setState({ ...state, isOpen: true })
                                  }
                                >
                                  <PlusSvg />
                                </div>
                              </div> */}
                            </div>
                          ))}
                        </div>

                        {/* Edit Address Field */}
                        {hasMobile ? (
                          <div className={"billing-address-field-section"}>
                            <div>
                              <label>Address</label>
                              {/* <button
                                className="edit-button"
                                onClick={() =>
                                  setState({ ...state, isOpen: true })
                                }
                              >
                                <EditSvg />
                              </button> */}
                            </div>
                            <p>
                              {state.activeAddress.serviceAddress2
                                ? `${state.activeAddress.serviceAddress1 || ''} ${state.activeAddress.serviceAddress2 || ''}`
                                : state.activeAddress.serviceAddress1 || ''}
                            </p>
                            <p>{`${state.activeAddress.city || ''}${state.activeAddress.city ? ',' : ''} ${state.activeAddress.stateAbrv || ''} ${state.activeAddress.postalCode || ''} `}</p>
                          </div>
                        ) : (
                          <></>
                        )}

                        {/* Detail Section */}
                        <div className={"billing-detail-section"}>
                          {!hasMobile ? (
                            <div style={{ paddingTop: "35px" }}>
                              <div className="services-field">
                                <label className="label-services">
                                  Address
                                </label>
                                <span className="input-services">
                                  {/* {state.activeAddress.serviceAddressDisplay
                                    ? state.activeAddress.serviceAddressDisplay
                                    : ""} */}
                                  <p>
                                    {state.activeAddress.serviceAddress2
                                      ? `${state.activeAddress.serviceAddress1 || ''} ${state.activeAddress.serviceAddress2 || ''}`
                                      : state.activeAddress.serviceAddress1 || ''}
                                  </p>
                                  <p>{(state.activeAddress.city && state.activeAddress.stateAbbrv && state.activeAddress.postalCode) ? `${state.activeAddress.city || ''}${state.activeAddress.city ? ',' : ''} ${state.activeAddress.stateAbrv || ''} ${state.activeAddress.postalCode || ''} ` : ''}</p>
                                </span>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {!hasMobile ? (
                            <>
                              <div className="padding-container-top">
                                <div className="services-field">
                                  <label className="label-services">
                                    Billing frequency
                                  </label>
                                  <div className="input-services services-list-option-title">
                                    <span style={{ fontWeight: 600 }}>
                                      {state?.activeAddress?.paymentFrequency
                                        ? `${capitalizeFirstLetterofFirstWord(billingPlanHandler(state.activeAddress.paymentFrequency))}`
                                        : ""}
                                    </span>
                                    <div
                                      style={{
                                        paddingTop: "10px"
                                      }}
                                      className="remove-addon"
                                    >
                                      {""}
                                    </div>
                                  </div>
                                </div>

                                <div className="services-list-nested">
                                  {state?.activeAddress?.subscriptions?.map(
                                    (d, index) =>
                                      d?.items.map((serviceItem, key) => (
                                        <span
                                          key={key}
                                          className="services-list-item"
                                        >
                                          <div className="services-field">
                                            <span className="input-services">
                                              {serviceItem.service &&
                                                serviceItem.amount
                                                ? `${serviceItem.service
                                                  ? capitalizeFirstLetterofFirstWord(
                                                    serviceItem.service
                                                  )
                                                  : ""
                                                } ($${serviceItem.amount}/${state?.activeAddress
                                                  ?.paymentFrequency
                                                  ? paymentFrequencyHandler(
                                                    state?.activeAddress
                                                      ?.paymentFrequency
                                                  )
                                                  : ""
                                                })`
                                                : `${serviceItem.service} (${state?.activeAddress?.paymentFrequency})`}
                                            </span>
                                          </div>

                                          {serviceItem.isAddOn && (
                                            <OptimizelyFeature feature="remove-service-addon-button">
                                              {(enabled, variables) =>
                                                enabled ? (
                                                  <div
                                                    style={{
                                                      paddingTop: "10px"
                                                    }}
                                                    className="remove-addon"
                                                  >
                                                    <div>
                                                      <Link
                                                        className="update-my-plan-text"
                                                        to={""}
                                                        data-testid={
                                                          "remove-service-button"
                                                        }
                                                      >
                                                        {/* <span
                                                          onClick={() =>
                                                            openModalHandler(
                                                              serviceItem
                                                            )
                                                          }
                                                        >
                                                          Remove */}
                                                        <MdDelete
                                                          onClick={() =>
                                                            openModalHandler(
                                                              serviceItem
                                                            )
                                                          }
                                                        />
                                                        {/* </span> */}
                                                      </Link>
                                                      <div
                                                      // style={{ paddingTop: "25px" }}
                                                      ></div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ""
                                                )
                                              }
                                            </OptimizelyFeature>
                                          )}
                                          {!serviceItem.isAddOn && (
                                            <div
                                              style={{
                                                paddingTop: "10px"
                                              }}
                                              className="remove-addon"
                                            >
                                              {""}
                                            </div>
                                          )}
                                        </span>
                                      ))
                                  )}
                                  {state?.activeAddress
                                    ?.subscriptionServicesEnrolled?.length <
                                    1 && (
                                      <span
                                        className="serviceLi"
                                        style={{ color: "#666" }}
                                      >
                                        No services
                                      </span>
                                    )}
                                  {/* {state.activeAddress?.data?.subscriptionServicesEnrolled.map((d, key) => (
                                  <ul key={key}>
                                    <li>
                                      <AppInput
                                        label={""}
                                        onChange={() => { }}
                                        placeholder={""}
                                        value={`${capitalizeFirstLetter(d.replace(/_/g, ' '))}`}

                                        inputStyle={{
                                          borderBottomWidth: 0,
                                          padding: 0,
                                        }}
                                        disabled
                                      />
                                    </li>
                                    {state.activeAddress?.data?.subscriptionServicesEnrolled.length < 1 &&
                                      <li className="serviceLi" style={{ color: "#666" }}>No services</li>
                                    }
                                  </ul>
                                ))} */}
                                </div>

                                {/* {userSub?.totalSubscriptionAmount > 0 &&
                                <AppInput
                                  label={""}
                                  onChange={() => { }}
                                  placeholder={""}
                                  value={`$${userSub?.totalSubscriptionAmount}/${state.activeAddress?.data?.paymentFrequency}`}
                                  disabled
                                  containerStyle={{ paddingTop: 0, paddinBottom: 0 }}
                                  inputStyle={{
                                    borderBottomWidth: 0,
                                    // fontWeight: 500,
                                    padding: 0,
                                  }}
                                // hasEditIcon="icon"
                                />
                              } */}
                              </div>

                              {/* {
                                // subscription.length <= 0
                                state?.activeAddress?.subscriptions?.length <=
                                0 ? (
                                  <div>
                                    <div className="padding-container-top">
                                      <AppInput
                                        label={"Services"}
                                        onChange={() => {}}
                                        placeholder={""}
                                        value={
                                          displayServiceType
                                            ? displayServiceType
                                            : ""
                                        }
                                        disabled
                                        containerStyle={{
                                          paddingTop: 0,
                                          paddinBottom: 0
                                        }}
                                        inputStyle={{
                                          borderBottomWidth: 0,
                                          padding: 0
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div></div>
                                )
                              } */}

                              {state?.activeAddress?.status && (
                                <>
                                  <div className="padding-container-top">
                                    <div className="services-field">
                                      <label className="label-services">
                                        Status
                                      </label>
                                      <span
                                        className="input-services"
                                        style={{
                                          color:
                                            state?.activeAddress?.status ===
                                              "active"
                                              ? "#3eb5ab"
                                              : state?.activeAddress?.status ===
                                                "canceled"
                                                ? "#ff0000"
                                              : state?.activeAddress?.status ===
                                               "no_payment_information"
                                               ? "#3eb5ab"
                                              : "black"
                                        }}
                                      >
                                        {`${state?.activeAddress?.status
                                          ? formatStatusDisplayText(
                                            state?.activeAddress?.status
                                          )
                                          : ""
                                          }`}
                                      </span>
                                    </div>
                                  </div>
                                  {state?.activeAddress?.subscriptions?.length >
                                    0 && (
                                      <div className="padding-container-top">
                                        <div className="services-field">
                                          <label className="label-services">
                                            Start date
                                          </label>
                                          <span className="input-services">
                                            {`${state?.activeAddress
                                              ?.subscriptions[0]
                                              ?.currentPeriodStartLabel
                                              ? nextPaymentDateHandler(
                                                state.activeAddress
                                                  .subscriptions[0]
                                                  .currentPeriodStartLabel
                                              )
                                              : ""
                                              }`}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                  {(state?.activeAddress?.subscriptions[0]
                                    ?.currentPeriodEndLabel ||
                                    state?.activeAddress?.cancelationDate) && (
                                      <div className="padding-container-top">
                                        <div className="services-field">
                                          <label className="label-services">
                                            End date
                                          </label>
                                          <span className="input-services">
                                            {`${state?.activeAddress
                                              ?.subscriptions[0]
                                              ?.currentPeriodEndLabel
                                              ? nextPaymentDateHandler(
                                                state.activeAddress
                                                  .subscriptions[0]
                                                  .currentPeriodEndLabel
                                              )
                                              : state?.activeAddress
                                                ?.cancelationDate
                                                ? nextPaymentDateHandler(
                                                  state?.activeAddress
                                                    ?.cancelationDate
                                                )
                                                : ""
                                              }`}
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                </>
                              )}
                              <div className="padding-container-top">
                                <div className="services-field">
                                  <label className="label-services">
                                    Pick up frequency
                                  </label>
                                  <span className="input-services">
                                    {`${state.activeAddress?.pickupFrequency
                                      ? capitalizeFirstLetterofFirstWord(
                                        state.activeAddress?.pickupFrequency
                                      )
                                      : ""
                                      }`}
                                  </span>
                                </div>
                                {/* <AppInput
                                  label={"Pick up"}
                                  onChange={() => {}}
                                  placeholder={""}
                                  value={`${
                                    state.activeAddress?.pickupFrequency
                                      ? capitalizeFirstLetterofFirstWord(
                                          state.activeAddress?.pickupFrequency
                                        )
                                      : ""
                                  }`}
                                  disabled
                                  containerStyle={{
                                    paddingTop: 0,
                                    paddinBottom: 0
                                  }}
                                  inputStyle={{
                                    borderBottomWidth: 0,
                                    padding: 0
                                  }}
                                  // hasEditIcon="icon"
                                /> */}

                                {/* <div
                                        className="form-grop Service-div"
                                        style={{ alignItems: "start" }}
                                        >
                                        <label className="services-text">Services</label>
                                        <div className="serviceUl input-container">
                                          <ul>
                                            {state.activeAddress?.data?.subscriptionServicesEnrolled.map((d, key) => (
                                              <li className="serviceLi" key={key}>{`${capitalizeFirstLetter(d.replace(/_/g, ' '))}`}</li>
                                            ))}
                                            {state.activeAddress?.data?.subscriptionServicesEnrolled.length < 1 &&
                                              <li className="serviceLi" style={{ color: "#666" }}>No services</li>
                                            }
                                          </ul>
                                        </div>
                                      </div> */}
                              </div>

                              {state.activeAddress?.nextPaymentDate ? (
                                <div className="padding-container-top">
                                    <div className="services-field">
                                        <label className="label-services"> Next payment date</label>
                                        <span className="input-services">
                                            {/* {nextPaymentDateHandler()} */}
                                            {state.activeAddress?.nextPaymentDate
                                            ? nextPaymentDateHandler(
                                                state.activeAddress?.nextPaymentDate
                                            )
                                            : "No payment date scheduled"}
                                        </span>
                                    </div>
                                </div>): ('')
                              }
                              
                              <OptimizelyFeature feature="view_credits">
                                {(enabled, variables) =>
                                  enabled ? (
                                    <div className="padding-container-top">
                                      {state.activeAddress.balance < 0 && (
                                        <div className="services-field">
                                          <label className="label-services">
                                            Credits
                                          </label>
                                          <span className="input-services">
                                            {"- $" +
                                              Math.abs(
                                                state.activeAddress.balance
                                              ).toFixed(2)}
                                          </span>
                                        </div>

                                        // <AppInput
                                        //   label={"Credits"}
                                        //   onChange={() => {}}
                                        //   placeholder={""}
                                        //   value={
                                        //     "- $" +
                                        //     Math.abs(
                                        //       state.activeAddress.balance
                                        //     ).toFixed(2)
                                        //   }
                                        //   inputStyle={{
                                        //     borderBottomWidth: 0,
                                        //     padding: 0
                                        //   }}
                                        //   disabled
                                        //   // hasEditIcon="more"
                                        // />
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )
                                }
                              </OptimizelyFeature>

                              {state.billingPortalUrls[currentIndexOfDealObj] ?
                              <>
                                <div style={{ paddingTop: "25px" }}> 
                                    <a href={state.billingPortalUrls[currentIndexOfDealObj]} target="_blank" rel="noreferrer">
                                        <span className="update-my-plan-text">View invoices & modify my payment methods</span>
                                    </a>
                                </div>
                                <div style={{paddingTop: "25px"}}></div>
                              </> : '' }

                              {discontinuedLocationsEligibleToReactivate.includes(state.activeAddress.locationId) === true && state.activeAddress.status === 'location_discontinued' ? 
                                <span
                                    onClick={() => setState({...state, enableSubscriptionReactivationModal: true})}
                                    style={{paddingTop: 30}}
                                    className='reactivate-subscription-btn'
                                >
                                    Reactivate my subscription
                                </span> : ''
                            }
                              

                              {/* {subscription.length > 0 ? (
                                <OptimizelyFeature feature="update_service_button">
                                  {(enabled, variables) =>
                                    enabled ? (
                                      <div>
                                        <div style={{ paddingTop: "30px" }}>
                                          <Link
                                            className="update-my-plan-text"
                                            to={
                                              "/dashboard/services/change-plan"
                                            }
                                          >
                                            <span
                                              onClick={() => hideMenuHandler()}
                                            >
                                              Change my plan
                                            </span>
                                          </Link>
                                          <div
                                            style={{ paddingTop: "25px" }}
                                          ></div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )
                                  }
                                </OptimizelyFeature>
                              ) : (
                                ""
                              )} */}

                              {/* {state.activeAddress?.subscriptionServicesEnrolled
                                ?.length > 0 &&
                                Object.keys(selectedDealObj).length > 0 &&
                                subscription?.length > 0 && (
                                  <OptimizelyFeature feature="cancel_service_button">
                                    {(enabled, variables) =>
                                      enabled ? (
                                        <div>
                                          <Link
                                            className="update-my-plan-text"
                                            to={
                                              "/dashboard/cancel-subscription"
                                            }
                                          >
                                            <span
                                            // onClick={() =>
                                            //   openModalHandler("service")
                                            // }
                                            >
                                              Cancel my plan
                                            </span>
                                          </Link>
                                          <div
                                            style={{ paddingTop: "25px" }}
                                          ></div>
                                        </div>
                                      ) : (
                                        ""
                                      )
                                    }
                                  </OptimizelyFeature>
                                )} */}
                            </>
                          ) : (
                            <>
                              <div className="form-grop">
                                <label>Billing frequency</label>
                                <div className="Service-mobile-div">
                                  <label>
                                    {state?.activeAddress?.paymentFrequency
                                      ? `${capitalizeFirstLetterofFirstWord(
                                        state?.activeAddress?.paymentFrequency
                                      )}`
                                      : ""}
                                  </label>
                                  {/* <label>{`${capitalizeFirstLetter(
                                    userSub?.paymentFrequency.replace(
                                      /ally/g,
                                      "al"
                                    )
                                  )} subscription`}</label> */}

                                  {/* <button
                                    className="edit-button"
                                    onClick={() => setShow(!show)}
                                  >
                                    {show === true ? (
                                      <MdOutlineKeyboardArrowDown />
                                    ) : (
                                      <MdOutlineKeyboardArrowUp />
                                    )}
                                  </button> */}
                                </div>
                                <div className="serviceUl input-container">
                                  <ul>
                                    {state?.activeAddress?.subscriptions?.map(
                                      (d, index) =>
                                        d.items.map((serviceItem, key) => (
                                          <li key={key}>
                                            {serviceItem.service &&
                                              serviceItem.amount
                                              ? `${serviceItem.service
                                                ? capitalizeFirstLetterofFirstWord(
                                                  serviceItem.service
                                                )
                                                : ""
                                              } ($${serviceItem.amount}/${state?.activeAddress
                                                ?.paymentFrequency
                                                ? paymentFrequencyHandler(
                                                  state?.activeAddress
                                                    ?.paymentFrequency
                                                )
                                                : ""
                                              })`
                                              : `${serviceItem.service} (${state?.activeAddress?.paymentFrequency})`}

                                            {/* {`${serviceItem.service} ($${serviceItem.amount
                                            }/${state.activeAddress.paymentFrequency}`} */}
                                            {/* {`${capitalizeFirstLetter(
                                            serviceItem.service.replace(
                                              /_/g,
                                              " "
                                            )
                                          )} ($${serviceItem.amount
                                            }/${state.activeAddress.paymentFrequency}`} */}
                                            {serviceItem.isAddOn && (
                                              <OptimizelyFeature feature="remove-service-addon-button">
                                                {(enabled, variables) =>
                                                  enabled ? (
                                                    <div>
                                                      <div>
                                                        <Link
                                                          className="update-my-plan-text"
                                                          to={""}
                                                          data-testid={
                                                            "remove-service-button"
                                                          }
                                                        >
                                                          {/* <span
                                                            onClick={() =>
                                                              openModalHandler(
                                                                serviceItem
                                                              )
                                                            }
                                                          >
                                                            Remove
                                                          </span> */}
                                                          <MdDelete
                                                            onClick={() =>
                                                              openModalHandler(
                                                                serviceItem
                                                              )
                                                            }
                                                          />
                                                        </Link>
                                                        <div
                                                        // style={{ paddingTop: "25px" }}
                                                        ></div>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )
                                                }
                                              </OptimizelyFeature>
                                            )}
                                          </li>
                                        ))
                                    )}
                                    {state?.activeAddress
                                      ?.subscriptionServicesEnrolled?.length <
                                      1 && (
                                        <li
                                          className="serviceLi"
                                          style={{ color: "#666" }}
                                        >
                                          No services
                                        </li>
                                      )}

                                    {/* {state.activeAddress?.data?.subscriptionServicesEnrolled.map((d, key) => (
                                        <li className="serviceLi" key={key}>{capitalizeFirstLetter(d.replace(/_/g, ' '))}</li>
                                      ))}
                                      {state.activeAddress?.data?.subscriptionServicesEnrolled.length < 1 &&
                                        <li className="serviceLi" style={{ color: "#666" }}>No services</li>
                                      } */}
                                  </ul>
                                </div>

                                {/* hide data for new ui Changing */}
                                {/* {state?.activeAddress?.data
                                  ?.totalSubscriptionAmount ? (
                                  <p
                                    style={{ fontSize: 16, fontWeight: 700 }}
                                  >{`$${userSub?.totalSubscriptionAmount}/${state.activeAddress?.data?.paymentFrequency}`}</p>
                                ) : (
                                  <p></p>
                                )} */}

                                {/* {subscription?.length <= 0 ? (
                                  <div style={{ paddingBottom: "10px" }}>
                                    <label>Services</label>
                                    <span
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        fontFamily: "Quicksand",
                                        color: "black"
                                      }}
                                    >
                                      {displayServiceType}
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )} */}

                                {state?.activeAddress?.status && (
                                  <>
                                    <div
                                      className="form-grop"
                                      style={{
                                        margin: "5px 0px",
                                        paddingTop: "0px"
                                      }}
                                    >
                                      <label>Status</label>
                                      <span
                                        style={{
                                          fontSize: 12,
                                          fontWeight: 400,
                                          fontFamily: "Quicksand",
                                          color:
                                            state?.activeAddress?.status ===
                                              "active"
                                              ? "#3eb5ab"
                                              : state?.activeAddress?.status ===
                                                "canceled"
                                                ? "#ff0000"
                                                : "black"
                                        }}
                                      >{`${state?.activeAddress?.status
                                        ? formatStatusDisplayText(
                                          state?.activeAddress?.status
                                        )
                                        : ""
                                        }`}</span>
                                    </div>
                                    {state?.activeAddress?.subscriptions
                                      ?.length > 0 && (
                                        <div
                                          className="form-grop"
                                          style={{
                                            margin: "5px 0px"
                                          }}
                                        >
                                          <label>Start date</label>
                                          <span
                                            style={{
                                              fontSize: 12,
                                              fontWeight: 400,
                                              fontFamily: "Quicksand",
                                              color: "black"
                                            }}
                                          >{`${state?.activeAddress?.subscriptions[0]
                                            ?.currentPeriodStartLabel
                                            ? nextPaymentDateHandler(
                                              state.activeAddress
                                                .subscriptions[0]
                                                .currentPeriodStartLabel
                                            )
                                            : ""
                                            }`}</span>
                                        </div>
                                      )}
                                    {(state?.activeAddress?.subscriptions[0]
                                      ?.currentPeriodEndLabel ||
                                      state?.activeAddress
                                        ?.cancelationDate) && (
                                        <div
                                          className="form-grop"
                                          style={{
                                            margin: "5px 0px"
                                          }}
                                        >
                                          <label>End date</label>
                                          <span
                                            style={{
                                              fontSize: 12,
                                              fontWeight: 400,
                                              fontFamily: "Quicksand",
                                              color: "black"
                                            }}
                                          >{`${state?.activeAddress?.subscriptions[0]
                                            ?.currentPeriodEndLabel
                                            ? nextPaymentDateHandler(
                                              state.activeAddress
                                                .subscriptions[0]
                                                .currentPeriodEndLabel
                                            )
                                            : state?.activeAddress
                                              ?.cancelationDate
                                              ? nextPaymentDateHandler(
                                                state?.activeAddress
                                                  ?.cancelationDate
                                              )
                                              : ""
                                            }`}</span>
                                        </div>
                                      )}
                                  </>
                                )}

                                <div
                                  className="form-grop"
                                  style={{
                                    margin: "5px 0px"
                                  }}
                                >
                                  <label>Pick up frequency</label>
                                  <span
                                    style={{
                                      fontSize: 12,
                                      fontWeight: 400,
                                      fontFamily: "Quicksand",
                                      color: "black"
                                    }}
                                  >{`${state.activeAddress?.pickupFrequency
                                    ? capitalizeFirstLetterofFirstWord(
                                      state.activeAddress?.pickupFrequency
                                    )
                                    : ""
                                    }`}</span>
                                </div>
                              </div>
                              {/* {show ? null : (
                                <>
                                  <div
                                    className="form-grop Service-div"
                                    style={{ alignItems: "start" }}
                                  >
                                    <label className="services-text">
                                      Services
                                    </label>

                                  
                                  </div>
                                </>
                              )}
                              <hr /> */}

                              {state.activeAddress?.nextPaymentDate ? (
                                <AppInput
                                    label={"Next payment date"}
                                    onChange={() => { }}
                                    placeholder={""}
                                    value={
                                    state.activeAddress?.nextPaymentDate
                                        ? nextPaymentDateHandler(
                                        state.activeAddress?.nextPaymentDate
                                        )
                                        : "No payment date scheduled"
                                    }
                                    inputStyle={{
                                    borderBottomWidth: 0,
                                    padding: 0
                                    }}
                                    disabled
                                // hasEditIcon="more"
                                />) : 
                                ('')
                            }

                              <OptimizelyFeature feature="view_credits">
                                {(enabled, variables) =>
                                  enabled ? (
                                    <>
                                      {state.activeAddress.balance < 0 && (
                                        <AppInput
                                          label={"Invoice credits"}
                                          onChange={() => { }}
                                          placeholder={""}
                                          value={
                                            "- $" +
                                            Math.abs(
                                              state.activeAddress.balance
                                            ).toFixed(2)
                                          }
                                          inputStyle={{
                                            borderBottomWidth: 0,
                                            padding: 0
                                          }}
                                          disabled
                                        // hasEditIcon="more"
                                        />
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )
                                }
                              </OptimizelyFeature>

                              {state.billingPortalUrls[currentIndexOfDealObj] ? 
                                <>
                                    <div style={{ paddingTop: "25px" }}> 
                                        <a href={state.billingPortalUrls[currentIndexOfDealObj]} target="_blank" rel="noreferrer">
                                            <span className="update-my-plan-text">View my subscription, invoices, & payment methods</span>
                                        </a>
                                    </div>
                                    <div style={{paddingTop: "25px"}}></div>
                                </> : ''
                                }

                              {/* {subscription.length > 0 ? (
                                <OptimizelyFeature feature="update_service_button">
                                  {(enabled, variables) =>
                                    enabled ? (
                                      <div className="form-grop">
                                        <div style={{ paddingTop: "10px" }}>
                                          <Link
                                            className="update-my-plan-text"
                                            to={
                                              "/dashboard/services/change-plan"
                                            }
                                          >
                                            <span
                                              onClick={() => hideMenuHandler()}
                                            >
                                              Change my plan
                                            </span>
                                          </Link>
                                          <div
                                            style={
                                              {
                                                // paddingTop: "7px"
                                                // paddingBottom: "17px"
                                              }
                                            }
                                          ></div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )
                                  }
                                </OptimizelyFeature>
                              ) : (
                                ""
                              )} */}

                              {/* {state.activeAddress?.subscriptionServicesEnrolled
                                ?.length > 0 &&
                                Object.keys(selectedDealObj).length > 0 &&
                                subscription.length > 0 && (
                                  <OptimizelyFeature feature="cancel_service_button">
                                    {(enabled, variables) =>
                                      enabled ? (
                                        <div className="form-grop">
                                          <div>
                                            <Link
                                              className="update-my-plan-text"
                                              to={
                                                "/dashboard/cancel-subscription"
                                              }
                                            >
                                              <span
                                              // onClick={() =>
                                              //   openModalHandler("cancel-service")
                                              // }
                                              >
                                                Cancel my plan
                                              </span>
                                            </Link>
                                            <div
                                              style={{
                                                // paddingTop: "7px",
                                                paddingBottom: "17px"
                                              }}
                                            ></div>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )
                                    }
                                  </OptimizelyFeature>
                                )} */}
                            </>

                          )}

                          {/* ----------------- */}

                          {!hasMobile ? (
                            <div style={{ marginTop: 20 }}>
                              <div>
                                {/* <Link to={""}>Change My Plan</Link> */}
                              </div>
                              <div style={{ marginTop: 10 }}>
                                {/* <Link to={""}>Cancel My Plan</Link> */}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        {hasMobile ? (
                          <div className={"account-cards"}>                            
                            {/* <Card
                              src={require("../../assets/images/card-payment.png")}
                              title={"Update my payment method"}
                              type={"img"}
                              onClick={() => {
                                // sethasPaymentSection(true);
                                navigate("/dashboard/billing");
                              }}
                            /> */}
                            {/* <Card
                              src={require("../../assets/images/pickup.png")}
                              title={"Pick up"}
                              type={"img"}
                              onClick={() => { }}
                            /> */}
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* Bottom Cards */}
                      </>
                    ) : ''}
                  </div>
                  <div className={"mt120"}>
                    <span></span>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* } */}
        </section>
      </div>
    </>
  );
}

export default Services;

const Card = ({ src = "", title, type = "", onClick }) => (
  <div className={"account-card"} onClick={onClick}>
    {type === "img" ? (
      <img src={src} alt={title} />
    ) : (
      <UserSvg fill={"#2A9B8D"} />
    )}
    <span>{title}</span>
  </div>
);

// {/* Address Sections */ }
// <div className={"address-cards"}>
//   {/* {state.address.map((item, index) => ( */}
//   <div
//     // key={item.contactId}
//     className={`address-card-container
//                               active
//                                 }`}
//   // className={`address-card-container ${item.contactId === state.activeAddress.contactId
//   //   ? "active"
//   //   : ""
//   //   }`}
//   >
//     <div
//       className={`address-card active
//                                   `}
//     // className={`address-card ${item.contactId ===
//     //   state.activeAddress.contactId
//     //   ? "active"
//     //   : ""
//     //   }`}
//     // onClick={() =>
//     //   setState({ ...state, activeAddress: item })
//     // }
//     >
//       <div>
//         <HomeServiceSvg />
//       </div>
//       <label>{`Address`}</label>
//       {/* <label>{`Address ${index + 1}`}</label> */}
//     </div>
//   </div>
//   {/* ))} */}
//   {/* <div
//                             className={`address-card add-address`}
//                             onClick={() => { }}
//                            >
//                             <div
//                               onClick={() =>
//                                 setState({ ...state, isOpen: true })
//                               }
//                             >
//                               <PlusSvg />
//                             </div>
//                           </div> */}
// </div>
