import axios from "axios";
import { sentryCaptureError, sentrySetUser } from "./components/utils/index";

const accountPortalBffBaseUrl = `${process.env.REACT_APP_ACCOUNT_PORTAL_BFF_BASE_URL}`;
const getLocationPickupDatePath = '/locations/';
const customerFindEndPointPath = '/customer/find';
const getpaymentMethodsEndPointPath = '/payment-methods';
const createBillingIntent = '/billing/setup-intent';
const getCustomersEndPointPath = '/customers/';
const getTicketEndPointPath = '/ticket';
const getCustomerSubscriptionPath = '/subscriptions';
const updateCustomerSubscriptionPath = '/subscriptions';
const getAddressByLocationPath = '/servicesByAddress';

const headers = {
  "Content-Type": "application/json"
};

const customerProfile = async (hubspotId) => {
  try {
    const res = await axios.get(
      `${accountPortalBffBaseUrl}${getCustomersEndPointPath}${hubspotId}`
    );
    if (res.data.exists) {
        sentrySetUser(hubspotId, res.data.email);
    
        return {
            exists: res.data.exists,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            email: res.data.email,
            phoneNumber: res.data.phoneNumber
            // routeDay: res.data.routeDay,
            // pickupFrequency: res.data.pickupFrequency,
            // locationId: res.data.locationId
        };
    } else {
      return {
        exists: res.data.exists,
        error: true,
        errorMsg: "No customer data"
      };
    }
  } catch (error) {
    sentryCaptureError(error, { extra: { hubspotId } });
    return {
      error: `Error with getting customer info: ${error}`
    };
  }
};

const updateCustomerProfile = async (hubspotId, body) => {
  try {
    const resp = await axios.put(
      `${accountPortalBffBaseUrl}${getCustomersEndPointPath}${hubspotId}`,
      body
    );

    if (resp.data.success) {
      return resp.data.success;
    } else {
      return "Error with updating customer";
    }
  } catch (error) {
    sentryCaptureError(error, { extra: { hubspotId, body: body } });
    return `Error with updating customer, ${error}`;
  }
};

const customerFind = async (email) => {
  try {
    const res = await axios.post(
      `${accountPortalBffBaseUrl}${customerFindEndPointPath}`,
      JSON.stringify({ email }),
      headers
    );

    if (res.data.emailExists) {
      return {
        exists: res.data.emailExists,
        contactId: res.data.contactId,
        dealId: res.data.dealId
      };
    } else {
      return {
        exists: res.data.emailExists,
        error: true,
        errorMsg: "No customer found in hubspot"
      };
    }
  } catch (error) {
    sentryCaptureError(error, { extra: { email } });
    return {
      error: `Error with getting customer info: ${error}`
    };
  }
};

const getCustomerSubscriptions = async (customerId) => {
  try {
    const res = await axios.get(
      `${accountPortalBffBaseUrl}${getCustomersEndPointPath}${customerId}${getCustomerSubscriptionPath}`
    );
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    sentryCaptureError(error, { extra: { customerId } });
    return [];
  }
};

const getBillingCardsAPI = async (customerId, isFranchised) => {
  const requestOptions = {
    method: "GET",
    redirect: "follow"
  };
  try {
    let isFranchisedBool = false;

    if (isFranchised) {
      isFranchisedBool = isFranchised;
    }

    const response = await fetch(
      `${accountPortalBffBaseUrl}${getCustomersEndPointPath}${customerId}${getpaymentMethodsEndPointPath}?isFranchised=${isFranchisedBool}`,
      requestOptions
    );

    return response.text();
  } catch (error) {
    sentryCaptureError(error, { extra: { customerId } });
    return {
      error: `Error with fetching billing cards: ${error}`
    };
  }
};

const getUserSubscriptions = async (customerId) => {
  const requestOptions = {
    method: "GET",
    redirect: "follow"
  };
  try {
    const response = await fetch(
      `${accountPortalBffBaseUrl}${getCustomersEndPointPath}${customerId}${getCustomerSubscriptionPath}`,
      requestOptions
    );
    if (response.status >= 200 && response.status < 300) {
      return response.text();
    }
  } catch (error) {
    sentryCaptureError(error, { extra: { customerId } });
    return {
      error: `Error with fetching services: ${error}`
    };
  }
};

const createBillingCardAPI = async (customerId, body) => {
  const requestOptions = {
    method: "POST",
    redirect: "follow"
  };

  try {
    const res = await axios.post(
      `${accountPortalBffBaseUrl}${getCustomersEndPointPath}${body.hubspotDealId}${getpaymentMethodsEndPointPath}`,
      body,
      requestOptions
    );
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    sentryCaptureError(error, { extra: { customerId, body } });
    return {
      error: `Error with creating new card: ${error}`
    };
  }
};

const createBillingPortalSession = async (body) => {
    // const requestOptions = {
    //     method: "POST",
    //     redirect: "follow"
    // };

    try {
        const res = await axios.post(`${accountPortalBffBaseUrl}/billing/portal-session`, body);

        if(res.data) {
            return res.data
        }
    } catch (error) {
        sentryCaptureError(error, { extra: { body } });
        return {
            error: `Error with creating billing portal session: ${error}`
        };        
    }
};

const updateBillingCardAPI = async ({
  isDefault = false,
  type,
  stripeCustomerId,
  hubspotDealId,
  line1,
  line2,
  city,
  state,
  postalCode,
  name,
  id,
  month,
  year,
  isFranchised
}) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify(
    isDefault
      ? {
        isDefault,
        type: type,
        paymentMethodId: id,
        isFranchised: isFranchised,
        stripeCustomerId: stripeCustomerId
      }
      : {
        address: {
          line1: line1,
          line2: line2,
          city: city,
          state: state,
          postalCode: postalCode,
          country: "US"
        },
        isFranchised: isFranchised,
        name: name,
        paymentMethodId: id,
        type: type,
        card: {
          expMonth: month,
          expYear: year
        },
        stripeCustomerId: stripeCustomerId,
      }
  );

  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  const response = await fetch(
    `${accountPortalBffBaseUrl}${getCustomersEndPointPath}${hubspotDealId}${getpaymentMethodsEndPointPath}`,
    requestOptions
  );
  return response.text();
};

const getPickupDate = async (params) => {
  let queryParams = {};

  // route_day OR zone and pickup_frequency must be passed in to get a 200 response
  // if (params.zone && params.pickupFrequency) {
  //     queryParams.zone = params.zone;
  //     queryParams.pickup_frequency = params.pickupFrequency;
  // } else
  if (params.routeDay) {
    queryParams.route_day = params.routeDay;
  }

  let pickupDates;

  try {
    pickupDates = await axios.get(
      `${accountPortalBffBaseUrl}${getLocationPickupDatePath}${params.locationId}/next-pickup-date`,
      { params: queryParams }
    );
    return pickupDates;
  } catch (error) {
    sentryCaptureError(error, { extra: { params } });
    return `Error while retrieving pickup date for ${params.locationId}: ${error}`;
  }
};

const removeCreditCard = async (customerId, deleteBody) => {
  try {
    const res = await axios.delete(
      `${accountPortalBffBaseUrl}${getCustomersEndPointPath}${customerId}${getpaymentMethodsEndPointPath}`,
      deleteBody
    );
    if (res.data) {
      return res.data;
    }
  } catch (error) {
    sentryCaptureError(error, { extra: { customerId, deleteBody } });
    return {
      error: `Error with removing credit card: ${error}`
    };
  }
};

const billingSetupIntent = async (data = {}) => {
  try {
    const raw = JSON.stringify(data);

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: raw,
      redirect: "follow"
    };

    const response = await fetch(
      `${accountPortalBffBaseUrl}${createBillingIntent}`,
      requestOptions
    );

    return response.json();
  } catch (error) {
    sentryCaptureError(error, { extra: { data } });
    return {
      error: `Error with billingSetupIntent: ${error}`
    };
  }
};
const locationByAddress = async (lat, lng, state) => {
  const requestOptions = {
    method: "GET",
    redirect: "follow"
  };

  try {
    const res = await axios.get(
      `${accountPortalBffBaseUrl}${getAddressByLocationPath}?state=${state}&lat=${lat}&lng=${lng}`,
      requestOptions
    );

    if (res.status === 200) {
      return res?.data?.services;
    }
  } catch (error) {
    sentryCaptureError(error, { extra: { state, lat, lng } });
    return {
      error: `Error with getting service by address: ${error}`
    };
  }
};

const updateServicePlanEvent = async (body, contactId, dealId) => {
  try {
    const res = await axios.put(
      `${accountPortalBffBaseUrl}${getCustomersEndPointPath}${contactId}${getCustomerSubscriptionPath}/${dealId}`,
      body
    );
    return res?.data?.services;
  } catch (error) {
    sentryCaptureError(error, { extra: { body, contactId, dealId } });
    return {
      error: `Error with updating service by address: ${error}`
    };
  }
};

const addContactTicket = async (body, contactId, dealId, form) => {
  var formdata = new FormData(form.current);
  // formData.append("fileProps", raw)
  formdata.append("dealId", body.dealId);
  formdata.append("category", body.category);
  formdata.append("subject", body.subject);
  formdata.append("message", body.message);
  formdata.append("callbackRequested", body.callbackRequested);
  if (body.files?.length > 0) {
    formdata.append("files", body.files[0]);
  }
  //   for (let i = 0; i < body.files.length; i++) {
  //     formdata.append('images[]', body.files[i])
  //  }
  try {
    let requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };

    const response = await fetch(
      `${accountPortalBffBaseUrl}${getCustomersEndPointPath}${contactId}${getTicketEndPointPath}`,
      requestOptions
    );

    if (response.status >= 200 && response.status < 300) {
      return response.json();
    }
  } catch (error) {
    sentryCaptureError(error, { extra: { body, contactId, dealId } });
    return {
      message: error?.message,
      error: `Error with submitting contact ticket: ${error}`
    };
  }
};

// const imageUpload = async (file) => {
//   try {
//     const response =
//       (await file) &&
//       file.map(async (item) => {
//         const uploadUrl = await ReactS3Client.uploadFile(item, item.name);
//         if (uploadUrl.status >= 200 && uploadUrl.status < 300) {
//           return uploadUrl.location;
//         }
//         return;
//       });
//     return Promise.all(response);
//   } catch (error) {
//     sentryCaptureError(error, { extra: { file } });
//     return {
//       message: error?.message,
//       error: `Error with uploading image: ${error}`
//     };
//   }
// };

const removeService = async (customerId, dealId, deleteBody) => {
  try {
    const raw = JSON.stringify(deleteBody);

    let requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: raw,
      redirect: "follow"
    };
    const response = await fetch(
      `${accountPortalBffBaseUrl}${getCustomersEndPointPath}${customerId}${getCustomerSubscriptionPath}/${dealId}`,
      requestOptions
    );
    if (response.status >= 200 && response.status < 300) {
      return response.json();
    }
  } catch (error) {
    sentryCaptureError(error, { extra: { customerId, dealId, deleteBody } });
    return {
      error: `Error with removing service: ${error}`
    };
  }
};

const updateCustomerSubscription = async (customerId, dealId, body) => {
    try {
        const raw = JSON.stringify(body);

        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: raw,
            redirect: "follow"
        };

        const response = await fetch(`${accountPortalBffBaseUrl}${getCustomersEndPointPath}${customerId}${updateCustomerSubscriptionPath}/${dealId}`,requestOptions);
        console.log('response');

        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
    } catch (err) {
        console.error(err)
        sentryCaptureError(err, { extra: { customerId, dealId, body } });
        return {
            error: 'Error while making call to reactivate the subscription'
        };
    }
};

export {
  customerProfile,
  updateCustomerProfile,
  customerFind,
  getCustomerSubscriptions,
  getUserSubscriptions,
  getPickupDate,
  getBillingCardsAPI,
  updateBillingCardAPI,
  removeCreditCard,
  removeService,
  billingSetupIntent,
  locationByAddress,
  updateServicePlanEvent,
  addContactTicket,
  createBillingCardAPI,
  updateCustomerSubscription,
  createBillingPortalSession
  // imageUpload
};
